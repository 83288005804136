<template>
    <div class="grid-list grid-list--dual">
        <CardBlog v-for="(item, index) in articles" :key="index" :article="item" />
    </div>
</template>

<script>
import store from "@/store/index.js"
import CardBlog from "@/pages/personal/components/card-blog"

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_GET_ARTICLES").then(() => {
            next();
        });
    },

    components: {
        CardBlog,
    },

    computed: {
        articles() {
            return this.$store.state.student.mainArticles
        }
    },

    setup() {

    },
}
</script>
