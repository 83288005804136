<template>
    <div class="card-border card-border--test">

        <router-link :to="{ name: 'knowledge-item', params: { slug: article.slug } }" class="card-border__link"></router-link>

        <div class="card-border__title subtitle">{{ article.name }}</div>
        <div class="card-border__description" v-html="article.short_description"></div>
        <div class="text-gray">{{ article.date }}</div>
    </div>
</template>

<script>

export default {
    props: {
        article: Object
    },
}
</script>
